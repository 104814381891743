<template>
  <chip class="property-status" :class="getStatusButtonClass()">
    {{ status }}
  </chip>
</template>

<script>
import Chip from "@/components/GlobalComponents/Chip";
export default {
  components: { Chip },
  mixins: [],
  props: {
    status: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getStatusButtonClass() {
      return this.toKebabCase(this.status);
    },
    toKebabCase(string) {
      return string
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/\s+/g, "-")
        .toLowerCase();
    }
  }
};
</script>

<style lang="scss">
.property-status {
  @mixin propertyStatus($class, $bg-color, $color) {
    &.#{$class} {
      background: $bg-color;
      color: $color;
    }
  }

  @include propertyStatus("active", $color-success, white);
  @include propertyStatus("inactive", $color-warn, white);
  @include propertyStatus("sold", $color-accent2, white);
  @include propertyStatus("rent-out", $color-accent, white);
  @include propertyStatus("dealt-by-other", $color-accent, white);
  @include propertyStatus("deleted", $color-danger, white);
}
</style>
